import {
  ACCOUNT_UPDATE,
  CONTRACT_LOADED,
  DATA_LOADED,
  RATE,
  THEME_TOGGLER,
} from "../constants/action-types";
import { getPoolContract, getERC20 } from "../../utils";
import { updatePools } from "../slices/poolsSlice";
import { ethers } from 'ethers';

import { getStakingTokenPrice } from "../../apollo";




export function accountUpdate(payload) {
  console.log(payload, "payload");
  return { type: ACCOUNT_UPDATE, payload };
}

export function contractLoaded(payload) {
  return { type: CONTRACT_LOADED, payload };
}

export function dataLoaded(payload) {
  console.log(payload, "payload");
  return { type: DATA_LOADED, payload };
}

export function ethtodollar(payload) {
  return { type: RATE, payload };
}

export function toggleDarkTheme() {
  return { type: THEME_TOGGLER };
}

export const loadUserPoolsData = async (pools, account, web3, dispatch) => {
  const ibgPrice = await getStakingTokenPrice(
    "0xf16cd087e1c2c747b2bdf6f9a5498aa400d99c24",
    false
  );

  // account = "0x8c069A810354bcD6536CaaDf33CcC30B66F6bCBa"
  const calls = await pools.map(async (pool) => {
    const depositTokenContract = getERC20(pool.depositTokenAddress, web3);
    const stakingContract = getPoolContract(pool.stakingContract, web3);
    // console.log("loadUserPoolsData", pool);

    const tokenPrice = await getStakingTokenPrice(
      pool.tokenInfo.tokenAddress,
      pool.tokenInfo.isLP
    );
    console.log("tokenPrice", tokenPrice, pool.name);

    const tvl = await depositTokenContract.methods
      .balanceOf(pool.stakingContract)
      .call();

    const maxLockDuration = await stakingContract.methods
      .maxLockDuration()
      .call();
    const maxBonus = await stakingContract.methods.maxBonus().call();
    const poolsShares = (await stakingContract.methods.totalSupply().call())/1e18;




    
    let payload = {};

    const theoreticalPoolTokenAmount =poolsShares/ 2;
    const poolTVL = Number(tvl / 1e18) * Number(tokenPrice);
    const theoreticalTVL =
      Number(theoreticalPoolTokenAmount) * Number(tokenPrice);
    const rewardsPerYearInUSD = pool.totalReward * Number(ibgPrice);
    const apr = (rewardsPerYearInUSD / theoreticalTVL) * 100;

    if (account) {
      const myTokenBalance = await depositTokenContract.methods
        .balanceOf(account)
        .call();


      const approvedAmount = await depositTokenContract.methods
        .allowance(account, pool.stakingContract)
        .call();
      const myStakeAmount = await stakingContract.methods
        .getTotalDeposit(account)
        .call();
      const pendingReward = await stakingContract.methods
        .withdrawableRewardsOf(account)
        .call();
      const withdrawableAmount = await stakingContract.methods
        .withdrawableRewardsOf(account)
        .call();
      const depositLength = await stakingContract.methods
        .getDepositsOfLength(account)
        .call();
      const deposits = [];
      console.log("depositLength",depositLength)
      for (let i = 0; i < depositLength; i++) {
        const dData = await stakingContract.methods
          .depositsOf(account, i)
          .call();
        const deposit = {
          id: i,
          amount: dData.amount,
          start: Number(dData.start) * 1000,
          end: Number(dData.end) * 1000,
        };



        deposits.push(deposit);
      }
      console.log("dDataaaaaaaa",deposits)


      payload = {
        myStakeAmount,
        pendingReward,
        claimedReward: 0,
        withdrawableAmount: 0,
        myTokenBalance: myTokenBalance,
        deposits,
        tokenPrice,
        isApproved: Number(approvedAmount) > 0,
      };
      console.log("myTokenBalance",myTokenBalance)

    }

    return {
      ...pool,
      ...payload,
      apr,
      tvl: poolTVL.toFixed(2),
      maxLockDuration,
      maxBonus,
    };
  });

  const data = await Promise.all(calls);
  dispatch(updatePools(data));
};
