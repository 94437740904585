import { useEffect } from "react";
import { useWallet } from "use-wallet";
import { getShortWallet } from "../../utils";

const Header = ({ onOpen }) => {
  const wallet = useWallet();

  useEffect(() => {
    wallet.connect();
  }, []);

  const ToggleButton = ({ onClick }) => {
    return (
      <div
        onClick={onClick}
        aria-label="Toggle menu"
        className="sc-AxiKw bVpPJu sc-fzqAbL llXNtr clickable"
        // type="button"
        style={{
          background: "null",
          alignSelf: "center",
        }}
      >
        <svg
          viewBox="0 0 24 24"
          width={40}
          fill="#010033"
          color="#010033"
          color="textSubtle"
          xmlns="http://www.w3.org/2000/svg"
          // className="sc-AxjAm lioUlx"
          // {...props}
        >
          <path
            color="#fff"
            d="M4 18h11c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1Zm0-5h8c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1ZM3 7c0 .55.45 1 1 1h11c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1Zm17.3 7.88L17.42 12l2.88-2.88a.996.996 0 1 0-1.41-1.41L15.3 11.3a.996.996 0 0 0 0 1.41l3.59 3.59c.39.39 1.02.39 1.41 0 .38-.39.39-1.03 0-1.42Z"
          />
        </svg>
      </div>
    );
  };
  return (
    <div className="staking-nav-container">
      <div
        className="row"
        style={{
          width: "auto",
          // margin:0,
          marginLeft: 20,
          marginRight: 0,
        }}
      >
        <ToggleButton
          onClick={() => {
            onOpen();
          }}
          color="#fff"
          height={50}
          width={50}
        />

        <a
          href="/"
          style={{
            marginLeft: 20,
          }}
        >
          <img
            // className="desktopLogo"
            alt=""
            height={70}
            src="/images/logo/logo.png"
            // src="https://bsc.ibg.finance/images/DarkLogo.png"
          />
          <img
            className="mobileLogo"
            alt=""
            src="/static/media/stakingLogoMobile.28c0aeb3.svg"
          />
        </a>
      </div>
      <div className="staking-nav-contents-container">
        <button
          className="staking-nav-wallet-button"
          style={{
            background: "red",
            color: "#fff",
            borderRadius: 10,
          }}
          onClick={() => {
            if (!wallet.account) {
              wallet.connect();
            }
          }}
        >
          {wallet.account ? getShortWallet(wallet.account) : "Connect Wallet"}
        </button>
        {/* <div>
          <button className="menu-button">
            <img alt="" src="/static/media/menu.aca47f39.svg" />
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Header;
