import POOL_ABI from "../BlockchainProvider/abi/pool.json";
import Web3 from "web3";

export const getShortWallet = (account) => {
  if (account) {
    return `${account.substring(0, 6)}....${account.substring(
      account.length - 4
    )}`;
  }
};

export const getPoolContract = (address, web3) => {
  return new web3.eth.Contract(POOL_ABI, address);
};

export const getERC20 = (address, web3) => {
  return new web3.eth.Contract(POOL_ABI, address);
};

export const MAX_UINT_256 = "99999999999999999999999999999999999999";

export const getHandsomeNumber = (raw) => {
  return Number(parseInt(raw)/1e18).toFixed(2);
};
