import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";

const client = new ApolloClient({
  uri: "https://api.thegraph.com/subgraphs/name/ianlapham/uniswapv2",
  cache: new InMemoryCache(),
});

const getTokenPrice = async (tokenAddress) => {
  const query = `
    {
        token(id:"${tokenAddress.toLowerCase()}"){
          
          derivedETH
        }
      
        bundle(id:1){
          ethPrice
        }
      }
      
      
      
      `;
  const resp = await client.query({
    query: gql`
      ${query}
    `,
  });
  let price = 0;
  try {
    console.log("sassasasasas",resp)
    const priceInETH = Number(resp.data.token.derivedETH);
    const ethPrice = Number(resp.data.bundle.ethPrice);
    price = Number(priceInETH * ethPrice).toFixed(2);
  } catch (err) {
    console.log("errr", err);
  }

  return price;
};

const getLPPrice = async (address) => {
  const query = `
    {
        pair(id:"${address.toLowerCase()}"){
          reserveUSD
            totalSupply
        }
      
       
      }
      
      
      
      `;

  const resp = await client.query({
    query: gql`
      ${query}
    `,
  });
  let price = 0;
  try {
    const totalLiquidity = Number(resp.data.pair.reserveUSD);
    const totalSupply = Number(resp.data.pair.totalSupply);
    price = Number(totalLiquidity / totalSupply).toFixed(2);

  } catch (err) {
    console.log("errr", err);
  }

  return price;
};
export const getStakingTokenPrice = async (tokenAddress, isLp) => {
  let price = 0;

  if (isLp) {
    price = await getLPPrice(tokenAddress);
  } else {
    price = await getTokenPrice(tokenAddress);
  }


  return price;
};
