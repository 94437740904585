import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";

import { Icon } from "react-icons-kit";
import { home } from "react-icons-kit/fa/home";
import { exchange } from "react-icons-kit/fa/exchange";
import { arrowDownB } from "react-icons-kit/ionicons/arrowDownB";
import { androidArrowDropup } from "react-icons-kit/ionicons/androidArrowDropup";
import { androidMoreHorizontal } from "react-icons-kit/ionicons/androidMoreHorizontal";
import { barChart } from "react-icons-kit/fa/barChart";

import { shield } from "react-icons-kit/fa/shield";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function FarmIcon(props) {
  return (
    <svg
      fill="#010033"
      viewBox="0 0 24 24"
      width={24}
      color="text"
      xmlns="http://www.w3.org/2000/svg"
      className="sc-AxjAm fqePGW"
      {...props}
    >
      <path d="M13.78 2.72a.75.75 0 0 1 0 1.06l-.97.97 1.22 1.22H20c1.1 0 2 .9 2 2v4.76a4.47 4.47 0 0 0-2.5-.76c-2.31 0-4.2 1.76-4.45 4h-3.14c.05-.33.09-.66.09-1 0-1.54-.59-2.94-1.54-4H11c1.1 0 2-.9 2-2V7.06l-1.25-1.25-.97.97a.75.75 0 1 1-1.06-1.06l3-3a.75.75 0 0 1 1.06 0Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 15a5 5 0 1 1-10 0 5 5 0 0 1 10 0Zm-2 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM19.5 12.97c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5Zm0 5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5Z"
      />
      <path d="M9 8.97H4c-.55 0-1-.44-1-1 0-.55.45-1 1-1h3c1.1 0 2 .9 2 2Z" />
    </svg>
  );
}

function PoolIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      width={24}
      fill="#010033"
      color="text"
      xmlns="http://www.w3.org/2000/svg"
      className="sc-AxjAm fqePGW"
      {...props}
    >
      <path d="M7.5 13a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-2Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 2a2 2 0 0 0-2 2v2.495c0 .437-.289.814-.682 1.005A5 5 0 0 0 4 12v5a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4v-5c0-.582-.1-1.142-.283-1.662l.072-.041A3.532 3.532 0 0 0 14.5 4.051V4a2 2 0 0 0-2-2h-3Zm0 4.25V4h3v2.25h-3Zm-.273 1.5a3.286 3.286 0 0 1-1.535 1.549A3 3 0 0 0 6 12v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-5a3 3 0 0 0-1.692-2.701 3.286 3.286 0 0 1-1.535-1.549H9.227ZM14.5 6.323v.172c0 .437.289.814.682 1.005.57.277 1.081.66 1.506 1.124l.101-.06a1.532 1.532 0 0 0-1.498-2.672l-.791.43Z"
      />
    </svg>
  );
}

function FLSIcon(props) {
  return (
    <svg
      viewBox="0 0 207.918 207.918"
      width={24}
      color="text"
      xmlns="http://www.w3.org/2000/svg"
      className="sc-AxjAm fqePGW"
      {...props}
    >
      <path d="M59.655 180.863a4.996 4.996 0 0 1-6.83 1.829C22.606 165.237 3.833 132.739 3.834 97.879c0-20.076 6.034-39.288 17.232-55.5l-6.344-6.267a4.999 4.999 0 0 1 2.969-8.526l21.274-2.333a5 5 0 0 1 5.521 5.454l-2.074 21.301a4.999 4.999 0 0 1-8.49 3.073l-5.612-5.544C18.899 63.775 13.834 80.463 13.834 97.88c0 31.3 16.856 60.48 43.993 76.153a4.999 4.999 0 0 1 1.828 6.83zm134.936-88.188a5 5 0 0 0-5 5v.204c0 48.457-39.422 87.879-87.879 87.879a89.38 89.38 0 0 1-5.308-.161l1.911-7.653a5 5 0 0 0-6.969-5.74l-19.385 9.068a5 5 0 0 0-1.88 7.53l12.848 17.117a5.002 5.002 0 0 0 8.851-1.79l2.169-8.688a98.96 98.96 0 0 0 7.766.317c53.971 0 97.879-43.908 97.879-97.915v-.168a5.004 5.004 0 0 0-5.003-5zm7.664-40.992a4.998 4.998 0 0 0-5.299-.658l-7.485 3.52C173.208 21.73 139.285 0 101.712 0 84.453.001 67.485 4.556 52.64 13.173a5 5 0 0 0 5.02 8.648C70.982 14.089 86.215 10.001 101.713 10c33.671 0 64.077 19.434 78.71 48.8l-7.89 3.711a5 5 0 0 0-.042 9.029l19.281 9.288a5.004 5.004 0 0 0 7.024-3.304l5.142-20.774a5.001 5.001 0 0 0-1.683-5.067zM70.811 138.821a5 5 0 1 1 0-10h25.902v-26.94h-7.435c-12.939 0-23.467-10.527-23.467-23.467 0-12.946 10.527-23.474 23.467-23.474h7.435v-5.746a5 5 0 1 1 10 0v5.746h25.902a5 5 0 1 1 0 10h-25.902v26.94h7.431c12.942 0 23.471 10.529 23.471 23.471s-10.529 23.47-23.471 23.47h-7.431v7.741a5 5 0 1 1-10 0v-7.741H70.811zm18.467-46.94h7.435V64.94h-7.435c-7.426 0-13.467 6.041-13.467 13.467 0 7.433 6.041 13.474 13.467 13.474zm17.435 36.94h7.431c7.428 0 13.471-6.043 13.471-13.47 0-7.428-6.043-13.471-13.471-13.471h-7.431v26.941z" />
    </svg>
  );
}
function PrvCard(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 24 24"
      width="24px"
      color="text"
      xmlns="http://www.w3.org/2000/svg"
      class="sc-bdfBwQ fafEdI"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.3137 3L15.2631 3.94934C14.5597 5.19866 14.7398 6.81097 15.8032 7.87441C16.8667 8.93786 18.479 9.11792 19.7283 8.4146L20.6777 9.36396L17.6569 12.3848L17.1287 11.8566C16.7382 11.4661 16.105 11.4661 15.7145 11.8566C15.3239 12.2471 15.3239 12.8803 15.7145 13.2708L16.2426 13.799L9.36396 20.6777L8.49923 19.8129C9.71921 18.5286 9.69924 16.4983 8.43932 15.2384C7.1794 13.9784 5.14908 13.9585 3.86473 15.1784L3 14.3137L9.87868 7.43503L10.2145 7.77081C10.605 8.16134 11.2382 8.16134 11.6287 7.77081C12.0192 7.38029 12.0192 6.74713 11.6287 6.3566L11.2929 6.02081L14.3137 3ZM12.8995 1.58579C13.6805 0.804738 14.9469 0.804738 15.7279 1.58579L17.299 3.15684C17.6895 3.54736 17.6895 4.18053 17.299 4.57105L17.2175 4.65257C16.7183 5.15173 16.7183 5.96103 17.2175 6.4602C17.7166 6.95936 18.5259 6.95936 19.0251 6.4602L19.1066 6.37868C19.4971 5.98816 20.1303 5.98816 20.5208 6.37868L22.0919 7.94975C22.8729 8.7308 22.8729 9.99713 22.0919 10.7782L10.7782 22.0919C9.99713 22.8729 8.7308 22.8729 7.94975 22.0919L6.37869 20.5208C5.98817 20.1303 5.98817 19.4971 6.37869 19.1066L7.02511 18.4602C7.52427 17.961 7.52427 17.1517 7.02511 16.6526C6.52594 16.1534 5.71664 16.1534 5.21748 16.6526L4.57106 17.299C4.18054 17.6895 3.54737 17.6895 3.15685 17.299L1.58579 15.7279C0.804738 14.9469 0.804738 13.6805 1.58579 12.8995L12.8995 1.58579ZM13.8787 8.6066C13.4882 8.21608 12.855 8.21608 12.4645 8.6066C12.0739 8.99712 12.0739 9.63029 12.4645 10.0208L13.4645 11.0208C13.855 11.4113 14.4882 11.4113 14.8787 11.0208C15.2692 10.6303 15.2692 9.99713 14.8787 9.6066L13.8787 8.6066Z"
      ></path>
    </svg>
  );
}

function HomeIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      width={24}
      fill="#010033"
      color="text"
      xmlns="http://www.w3.org/2000/svg"
      className="sc-AxjAm fqePGW"
      {...props}
    >
      <path d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1Z" />
    </svg>
  );
}

function TradeIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      width={24}
      color="text"
      fill="#010033"
      xmlns="http://www.w3.org/2000/svg"
      className="sc-AxjAm fqePGW"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m18.86 4.86 2.79 2.79c.19.19.19.51-.01.7l-2.79 2.79c-.31.32-.85.1-.85-.35V9H4c-.55 0-1-.45-1-1s.45-1 1-1h14V5.21c0-.45.54-.67.86-.35ZM5.14 19.14l-2.79-2.79a.492.492 0 0 1 .01-.7l2.79-2.79c.31-.32.85-.1.85.35V15h14c.55 0 1 .45 1 1s-.45 1-1 1H6v1.79c0 .45-.54.67-.86.35Z"
      />
    </svg>
  );
}

function PriceChart(props) {
  return (
    <svg
      fill="#010033"
      viewBox="0 0 24 24"
      width={24}
      color="text"
      xmlns="http://www.w3.org/2000/svg"
      className="sc-AxjAm fqePGW"
      {...props}
    >
      <path d="M5 7a1 1 0 0 0-2 0v11a2 2 0 0 0 2 2h15a1 1 0 1 0 0-2H5V7Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 17H7a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h3v-1a1 1 0 0 1 1-1h3V7a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1Zm-3-9h2v7h-2V8Zm-4 7h2v-4h-2v4Zm-2-2H8v2h2v-2Z"
      />
    </svg>
  );
}

function AuditIcon(props) {
  return (
    <svg
      fill="#010033"
      viewBox="-60 -30 600 600"
      width={24}
      color="text"
      xmlns="http://www.w3.org/2000/svg"
      className="sc-AxjAm fqePGW"
      {...props}
    >
      <path d="M217.996 158.457c-53.793 0-97.555 43.762-97.555 97.55 0 53.794 43.762 97.555 97.555 97.555 53.79 0 97.55-43.761 97.55-97.554 0-53.79-43.76-97.551-97.55-97.551Zm57.918 79.18-69.887 69.886a14.984 14.984 0 0 1-10.625 4.403 14.96 14.96 0 0 1-10.62-4.403l-26.708-26.707c-5.867-5.863-5.867-15.375 0-21.242 5.863-5.867 15.375-5.867 21.242 0l16.086 16.086 59.27-59.265c5.863-5.868 15.375-5.868 21.242 0 5.867 5.863 5.867 15.375 0 21.242Zm0 0" />
      <path d="m435.488 138.918-.015-.398a533.678 533.678 0 0 1-.461-15.91c-.418-28.231-22.86-51.583-51.094-53.16-58.867-3.286-104.406-22.481-143.316-60.407l-.332-.316c-12.692-11.637-31.836-11.637-44.532 0l-.332.316c-38.91 37.926-84.449 57.121-143.316 60.41C23.86 71.027 1.414 94.38.996 122.613a527.685 527.685 0 0 1-.46 15.907l-.024.925C-.633 199.473-2.055 274.18 22.937 341.988c13.743 37.29 34.555 69.703 61.856 96.348 31.094 30.344 71.82 54.434 121.047 71.598a37.432 37.432 0 0 0 4.96 1.351c2.391.477 4.798.715 7.204.715 2.406 0 4.816-.238 7.203-.715a37.463 37.463 0 0 0 4.98-1.36c49.168-17.195 89.852-41.296 120.918-71.636 27.29-26.652 48.102-59.074 61.856-96.371 25.086-68.012 23.668-142.86 22.527-203ZM217.996 383.605c-70.36 0-127.598-57.238-127.598-127.597 0-70.36 57.239-127.598 127.598-127.598 70.356 0 127.598 57.238 127.598 127.598s-57.242 127.597-127.598 127.597Zm0 0" />
    </svg>
  );
}

function HowToIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 0V20H2V16H0V14H2V11H0V9H2V6H0V4H2V0H6ZM18.005 0C19.107 0 20 0.898 20 1.99V18.01C20 19.109 19.107 20 18.005 20H8V0H18.005Z"
        fill="#010033"
      />
    </svg>
  );
}

function MoreIcon(props) {
  return (
    <svg
      width="18"
      height="4"
      viewBox="0 0 18 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM16 0C14.9 0 14 0.9 14 2C14 3.1 14.9 4 16 4C17.1 4 18 3.1 18 2C18 0.9 17.1 0 16 0ZM9 0C7.9 0 7 0.9 7 2C7 3.1 7.9 4 9 4C10.1 4 11 3.1 11 2C11 0.9 10.1 0 9 0Z"
        fill="#010033"
      />
    </svg>
  );
}

function Valuts(props) {
  return (
    <svg
      fill="#010033"
      viewBox="0 0 207.918 207.918"
      width={24}
      color="text"
      xmlns="http://www.w3.org/2000/svg"
      className="sc-AxjAm fqePGW"
      {...props}
    >
      <path d="M59.655 180.863a4.996 4.996 0 0 1-6.83 1.829C22.606 165.237 3.833 132.739 3.834 97.879c0-20.076 6.034-39.288 17.232-55.5l-6.344-6.267a4.999 4.999 0 0 1 2.969-8.526l21.274-2.333a5 5 0 0 1 5.521 5.454l-2.074 21.301a4.999 4.999 0 0 1-8.49 3.073l-5.612-5.544C18.899 63.775 13.834 80.463 13.834 97.88c0 31.3 16.856 60.48 43.993 76.153a4.999 4.999 0 0 1 1.828 6.83zm134.936-88.188a5 5 0 0 0-5 5v.204c0 48.457-39.422 87.879-87.879 87.879a89.38 89.38 0 0 1-5.308-.161l1.911-7.653a5 5 0 0 0-6.969-5.74l-19.385 9.068a5 5 0 0 0-1.88 7.53l12.848 17.117a5.002 5.002 0 0 0 8.851-1.79l2.169-8.688a98.96 98.96 0 0 0 7.766.317c53.971 0 97.879-43.908 97.879-97.915v-.168a5.004 5.004 0 0 0-5.003-5zm7.664-40.992a4.998 4.998 0 0 0-5.299-.658l-7.485 3.52C173.208 21.73 139.285 0 101.712 0 84.453.001 67.485 4.556 52.64 13.173a5 5 0 0 0 5.02 8.648C70.982 14.089 86.215 10.001 101.713 10c33.671 0 64.077 19.434 78.71 48.8l-7.89 3.711a5 5 0 0 0-.042 9.029l19.281 9.288a5.004 5.004 0 0 0 7.024-3.304l5.142-20.774a5.001 5.001 0 0 0-1.683-5.067zM70.811 138.821a5 5 0 1 1 0-10h25.902v-26.94h-7.435c-12.939 0-23.467-10.527-23.467-23.467 0-12.946 10.527-23.474 23.467-23.474h7.435v-5.746a5 5 0 1 1 10 0v5.746h25.902a5 5 0 1 1 0 10h-25.902v26.94h7.431c12.942 0 23.471 10.529 23.471 23.471s-10.529 23.47-23.471 23.47h-7.431v7.741a5 5 0 1 1-10 0v-7.741H70.811zm18.467-46.94h7.435V64.94h-7.435c-7.426 0-13.467 6.041-13.467 13.467 0 7.433 6.041 13.474 13.467 13.474zm17.435 36.94h7.431c7.428 0 13.471-6.043 13.471-13.47 0-7.428-6.043-13.471-13.471-13.471h-7.431v26.941z" />
    </svg>
  );
}

function ListItems(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);

  const handleClick2 = () => {
    setOpen2(!open2);
  };

  const handleClick4 = () => {
    setOpen4(!open4);
  };

  const handleClick3 = () => {
    setOpen3(!open3);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleNav = (url) => {
    window.open(url, "_blank").focus();
  };
  return (
    <div>
      <ListItem
        button
        onClick={() => {
          handleNav("https://eth.ibg.finance");
        }}
      >
        {window.screen.width > 900 && (
          <ListItemIcon
            onClick={() => {
              props.closed && props.setclosed();
            }}
            style={{ color: "#010033", padding: "10px 0px" }}
          >
            <HomeIcon />
          </ListItemIcon>
        )}
        {window.screen.width < 900 && !props.closed && (
          <ListItemIcon
            onClick={() => {
              props.closed && props.setclosed();
            }}
            style={{ color: "#010033" }}
          >
            <HomeIcon />
          </ListItemIcon>
        )}
        {!props.closed && (
          <ListItemText>
            <span
              style={{ color: "#010033", fontWeight: "bold", fontSize: "18px" }}
            >
              Home
            </span>
          </ListItemText>
        )}
      </ListItem>

      {
        <ListItem button onClick={handleClick}>
          {window.screen.width > 900 && (
            <ListItemIcon
              onClick={() => {
                props.closed && props.setclosed();
              }}
              style={{ color: "#010033", padding: "10px 0px" }}
            >
              <TradeIcon />
            </ListItemIcon>
          )}
          {window.screen.width < 900 && !props.closed && (
            <ListItemIcon
              onClick={() => {
                props.closed && props.setclosed();
              }}
              style={{ color: "#010033" }}
            >
              <TradeIcon />
              {/* <Icon icon={exchange} size={18} /> */}
            </ListItemIcon>
          )}
          {!props.closed && (
            <ListItemText>
              <span
                style={{
                  color: "#010033",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                Trade
              </span>
            </ListItemText>
          )}
          {!props.closed ? (
            open ? (
              <Icon
                icon={androidArrowDropup}
                style={{ color: "#010033" }}
                size={10}
              />
            ) : (
              <Icon icon={arrowDownB} size={10} style={{ color: "#010033" }} />
            )
          ) : (
            ""
          )}
        </ListItem>
      }
      {!props.closed && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              onClick={() => {
                handleNav(
                  "https://app.uniswap.org/#/swap?outputCurrency=0xf16cd087e1c2c747b2bdf6f9a5498aa400d99c24&use=V2&chain=mainnet"
                );
              }}
            >
              <ListItemText
                style={{
                  color: "#010033",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                {" "}
                Exchange{" "}
              </ListItemText>
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => {
                handleNav(
                  "https://app.uniswap.org/#/add/v2/0xf16cd087e1c2c747b2bdf6f9a5498aa400d99c24/ETH?chain=mainnet"
                );
              }}
            >
              <ListItemText
                style={{
                  color: "#010033",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                {" "}
                Liquidty{" "}
              </ListItemText>
            </ListItem>
          </List>
        </Collapse>
      )}

      <ListItem
        button
        onClick={() => {
          handleNav("https://eth.ibg.finance/farms");
        }}
      >
        {window.screen.width > 900 && (
          <ListItemIcon
            onClick={() => {
              props.closed && props.setclosed();
            }}
            style={{ padding: "10px 0px", padding: "10px 0px" }}
          >
            <FarmIcon></FarmIcon>
          </ListItemIcon>
        )}
        {window.screen.width < 900 && !props.closed && (
          <ListItemIcon
            onClick={() => {
              props.closed && props.setclosed();
            }}
          >
            <FarmIcon></FarmIcon>
          </ListItemIcon>
        )}
        {!props.closed && (
          <ListItemText
            onClick={() => {
              console.log("clincccdd");
            }}
          >
            <span
              style={{ color: "#010033", fontWeight: "bold", fontSize: "18px" }}
            >
              Farming
            </span>
          </ListItemText>
        )}
      </ListItem>

      <ListItem
        button
        onClick={() => {
          handleNav("https://eth.ibg.finance/pools");
        }}
      >
        {window.screen.width > 900 && (
          <ListItemIcon
            onClick={() => {
              props.closed && props.setclosed();
            }}
            style={{ color: "#010033", padding: "10px 0px" }}
          >
            <PoolIcon fill="#010033"></PoolIcon>
          </ListItemIcon>
        )}
        {window.screen.width < 900 && !props.closed && (
          <ListItemIcon
            onClick={() => {
              props.closed && props.setclosed();
            }}
            style={{ color: "#010033" }}
          >
            <PoolIcon fill="#010033"></PoolIcon>
          </ListItemIcon>
        )}
        {!props.closed && (
          <ListItemText>
            <span
              style={{ color: "#010033", fontWeight: "bold", fontSize: "18px" }}
            >
              Staking
            </span>
          </ListItemText>
        )}
      </ListItem>

      <ListItem
        button
        onClick={() => {
          handleNav("/");
        }}
      >
        {window.screen.width > 900 && (
          <ListItemIcon
            onClick={() => {
              props.closed && props.setclosed();
            }}
            style={{ color: "#010033", padding: "10px 0px" }}
          >
            <FLSIcon fill="#010033"></FLSIcon>
          </ListItemIcon>
        )}
        {window.screen.width < 900 && !props.closed && (
          <ListItemIcon
            onClick={() => {
              props.closed && props.setclosed();
            }}
            style={{ color: "#010033" }}
          >
            <FLSIcon fill="#010033"></FLSIcon>
          </ListItemIcon>
        )}
        {!props.closed && (
          <ListItemText>
            <span
              style={{ color: "#010033", fontWeight: "bold", fontSize: "18px" }}
            >
              Flexible Lock Staking
            </span>
          </ListItemText>
        )}
      </ListItem>

      {/* <ListItem
        button
        onClick={() => {
          handleNav("/");
        }}
      >
        {window.screen.width > 900 && (
          <ListItemIcon
            onClick={() => {
              props.closed && props.setclosed();
            }}
            style={{ color: "#010033", padding: "10px 0px" }}
          >
            <Valuts fill="#010033" />
          </ListItemIcon>
        )}
        {window.screen.width < 900 && !props.closed && (
          <ListItemIcon
            onClick={() => {
              props.closed && props.setclosed();
            }}
            style={{ color: "#010033" }}
          >
            <Valuts fill="#010033" />
          </ListItemIcon>
        )}
        {!props.closed && (
          <ListItemText>
            <span
              style={{ color: "#010033", fontWeight: "bold", fontSize: "18px" }}
            >
              iBG Super Staking
            </span>
          </ListItemText>
        )}
      </ListItem> */}

      {
        <ListItem button onClick={handleClick2}>
          {window.screen.width > 900 && (
            <ListItemIcon
              onClick={() => {
                props.closed && props.setclosed();
              }}
              style={{ color: "#010033", padding: "10px 0px" }}
            >
              <PriceChart />
            </ListItemIcon>
          )}
          {window.screen.width < 900 && !props.closed && (
            <ListItemIcon
              onClick={() => {
                props.closed && props.setclosed();
              }}
              style={{ color: "#010033" }}
            >
              <PriceChart />
            </ListItemIcon>
          )}
          {!props.closed && (
            <ListItemText>
              <span
                style={{
                  color: "#010033",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                Price Charts
              </span>
            </ListItemText>
          )}
          {!props.closed ? (
            open ? (
              <Icon
                icon={androidArrowDropup}
                style={{ color: "#010033" }}
                size={10}
              />
            ) : (
              <Icon icon={arrowDownB} size={10} style={{ color: "#010033" }} />
            )
          ) : (
            ""
          )}
        </ListItem>
      }

      {!props.closed && (
        <Collapse in={open2} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              onClick={() => {
                handleNav(
                  "https://dex.guru/token/0xf16cd087e1c2c747b2bdf6f9a5498aa400d99c24-eth"
                );
              }}
            >
              <ListItemText
                style={{
                  color: "#010033",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                {" "}
                Dex Guru{" "}
              </ListItemText>
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              onClick={() => {
                handleNav(
                  "https://www.dextools.io/app/ether/pair-explorer/0xfecc3b6fab9e07e1f2e6c712d17f86ff2b5ee5ac"
                );
              }}
            >
              <ListItemText
                style={{
                  color: "#010033",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                {" "}
                DexTools{" "}
              </ListItemText>
            </ListItem>
          </List>
        </Collapse>
      )}

      {
        <ListItem button onClick={handleClick3}>
          {window.screen.width > 900 && (
            <ListItemIcon
              onClick={() => {
                props.closed && props.setclosed();
              }}
              style={{ color: "#010033", padding: "10px 0px" }}
            >
              <AuditIcon />
            </ListItemIcon>
          )}
          {window.screen.width < 900 && !props.closed && (
            <ListItemIcon
              onClick={() => {
                props.closed && props.setclosed();
              }}
              style={{ color: "#010033" }}
            >
              <AuditIcon />
            </ListItemIcon>
          )}
          {!props.closed && (
            <ListItemText>
              <span
                style={{
                  color: "#010033",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                Audit
              </span>
            </ListItemText>
          )}
          {!props.closed ? (
            open ? (
              <Icon
                icon={androidArrowDropup}
                style={{ color: "#010033" }}
                size={10}
              />
            ) : (
              <Icon icon={arrowDownB} size={10} style={{ color: "#010033" }} />
            )
          ) : (
            ""
          )}
        </ListItem>
      }

      {!props.closed && (
        <Collapse in={open3} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              onClick={() => {
                handleNav(
                  "https://github.com/IBG-Finance-Defi/IBG-Farms-build/blob/main/audits/eth.pdf"
                );
              }}
            >
              <ListItemText
                style={{
                  color: "#010033",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                {" "}
                Audit Report{" "}
              </ListItemText>
            </ListItem>
          </List>
        </Collapse>
      )}
    </div>
  );
}

export default ListItems;
