import { useWallet } from "use-wallet";

export default () => {
  const { account, connect } = useWallet();

  return (
    <button
      className="stake-button width-fix"
      onClick={() => {
        connect();
      }}
    >
      Connect Wallet
    </button>
  );
};
