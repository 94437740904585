import React, { useEffect, useState } from "react";
import { useWallet } from "use-wallet";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BigNumber from "bignumber.js"
import {
  getPoolContract,
  getERC20,
  MAX_UINT_256,
  getHandsomeNumber,
} from "../utils";
import Web3 from "web3";
import { loadUserPoolsData } from "../redux/actions";
import moment from "moment";

const ONEMONTHSECONDS = 30*24*60*60;
const Stake = () => {
  const search = useLocation().search;
  const poolId = new URLSearchParams(search).get("id");

  const pools = useSelector((state) => state.pools.data);

  const pool = useSelector((state) => {
    const _pool = state.pools.data.filter(
      (item) => parseInt(item.id) === parseInt(poolId)
    );
    if (_pool.length > 0) {
      return _pool[0];
    }
  });

  const [monthInput, setMonthInput] = useState(1);
  const [isLoading, setLoading] = useState(false);

  const [tokenInput, setTokenInput] = useState();

  const [weight, setWeight] = useState(0);
  const [estApr, setEstApr] = useState(1);
  const dispatch = useDispatch();
  const { ethereum, account, connect } = useWallet();

  const renderLoader = () => {
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  };

  useEffect(() => {
    if (pool && pool.apr) {
      setEstApr(pool.apr.toFixed(2));
    }
  }, [pool]);

  const handleClaimReward = async () => {
    setLoading(true);
    const web3 = new Web3(ethereum);

    const stakingContract = getPoolContract(pool.stakingContract, web3);

    try {
      await stakingContract.methods.claimRewards(account).send({
        from: account,
      });
    } catch (err) {
      console.error(err);
    }

    updateMyData();
    setLoading(false);
  };

  const renderButton = () => {
    if (!account) {
      return (
        <button
          className="stake-button details-page-button"
          style={{
            background: "red",
            color: "#fff",
            borderRadius: 10,
          }}
          onClick={() => {
            connect();
          }}
        >
          Connect Wallet
        </button>
      );
    } else {
      if (!pool.isApproved) {
        // return (
        //   <button
        //     disabled={isLoading}
        //     className="stake-button details-page-button"
        //     onClick={() => {
        //       handleApprove();
        //     }}
        //   >
        //     {isLoading ? renderLoader() : "Approve Contract"}
        //   </button>
        // );
      } else {
        return (
          <>
            {/* <button
              disabled={isLoading}
              className="stake-button details-page-button"
              onClick={() => {
                handleStake();
              }}
            >
              {isLoading ? renderLoader() : "Stake"}
            </button> */}

            <button
              className={`stake-button details-page-button ${
                pool.pendingReward == 0 ? "disabled" : ""
              }`}
              style={{ marginTop: 10 }}
              disabled={pool.pendingReward == 0 || isLoading}
              onClick={() => {
                handleClaimReward();
              }}
            >
              {isLoading ? renderLoader() : "Claim Rewards"}
            </button>
          </>
        );
      }
    }
  };

  const handleApprove = async () => {
    setLoading(true);

    const web3 = new Web3(ethereum);

    const stakingTokenContract = getERC20(pool.depositTokenAddress, web3);

    try {
      await stakingTokenContract.methods
        .approve(pool.stakingContract, MAX_UINT_256)
        .send({
          from: account,
        });
    } catch (err) {
      console.error(err);
    }

    updateMyData();
    setLoading(false);
  };

  const updateMyData = () => {
    const web3 = new Web3(ethereum);

    loadUserPoolsData(pools, account, web3, dispatch);
  };

  const handleStake = async () => {
    setLoading(true);
    const web3 = new Web3(ethereum);

    const stakingContract = getPoolContract(pool.stakingContract, web3);
    const duration = Number(monthInput) * ONEMONTHSECONDS;
    let amount = Number(tokenInput);
    if (amount == 0) {
      alert("Invalid Amount");
      return;
    }

    try {
      await stakingContract.methods
        .deposit(Web3.utils.toWei(String(amount)), duration, account)
        .send({
          from: account,
        });
      setTokenInput(0);
    } catch (err) {
      console.error(err);
    }

    updateMyData();
    setLoading(false);
  };

  const handleWithdraw = async (id) => {
    setLoading(true);
    const web3 = new Web3(ethereum);

    const stakingContract = getPoolContract(pool.stakingContract, web3);

    try {
      await stakingContract.methods.withdraw(id, account).send({
        from: account,
      });
    } catch (err) {
      console.error(err);
    }

    updateMyData();
    setLoading(false);
  };

  useEffect(() => {
    if (monthInput) {
      let _weight =
        (1e18 +
          (Number(pool.maxBonus) * (Number(monthInput) * ONEMONTHSECONDS)) /
            Number(31536000)) /
        1e18;

      _weight = _weight.toFixed(2);
      if(_weight.toString() === "1.99"){
        _weight = 2;
      }
      setWeight(_weight);
      setEstApr(Number((pool.apr * _weight) / 2).toFixed(2));
    }
  }, [monthInput, pool.apr]);

  const weeksToMonth = (weeks) => {
    console.log("months toW", monthInput / 4, monthInput);
    let months = parseInt(monthInput / 4);
    if (months > 12) {
      months = 12;
    }
    return months;
  };

  const MonthsToWeek = (month) => {
    let weeks = parseInt(monthInput * 4);
    // if (months > 12) {
    //   months = 12;
    // }
    return weeks;
  };
  return (
    <div className="staking-container">
      {pool ? (
        <div
          className="details-container"
          style={{ opacity: 1, transform: "none" }}
        >
          <div
            className="card mobile-pool-card"
            style={{ opacity: 1, transform: "none" }}
          >
            <span className="details-headline" >
              <h3 className="mobile-pool-name">
                {pool.logos.map((src) => {
                  return <img width={24} height={24} src={src} />;
                })}{" "}
                {pool.name}
              </h3>
            
            </span>
            
            <span className="staking-title" style={{
              fontSize:20,
              marginTop:-10,
              marginBottom:10
            }}>Flexible Lock Staking</span>
            {account ? (
              <>
                <div className="deposit-row">
                  {monthInput ? (
                    <span className="deposit-text">
                      Lock for:{" "}
                      <span className="mobile-pool-number deposit-number">
                        {monthInput} Months
                      </span>
                    </span>
                  ) : null}

                  
                  {weight ? (
                    <span className="deposit-text">
                      Weight:{" "}
                      <span className="mobile-pool-number">{weight}</span>
                    </span>
                  ) : null}
                </div>

            
                <span className="deposit-text">
                     
                     <span className="mobile-pool-number deposit-number">
                       1 Month : 30 Days
                     </span>
                   </span>
                <div className="range__wrapper">
                  <p>
                    <input
                      type="range"
                      id="styled-range"
                      name="styled-range"
                      min={1}
                      max={12}
                      // defaultValue={4}
                      step={1}
                      value={monthInput}
                      onChange={(e) => {
                        setMonthInput(e.target.value);
                      }}
                      list="styled-range-list"
                      className="range--progress"
                      style={{
                        width: "100%",
                      }}
                    />
                    <datalist id="styled-range-list">
                      <option value={0} />
                      <option value={10} />
                      <option value={20} />
                      <option value={40} />
                      <option value={52} />
                    </datalist>
                  </p>
                </div>
                <div className="mobile-card-gap" />
                <div className="mobile-pool-line deposit-text">
                  <p className="mobile-pool-line deposit-text">Amount</p>
                  <p className="mobile-pool-line deposit-text">
                    Balance:&nbsp;
                    <span className="mobile-pool-number">
                      { new BigNumber(pool.myTokenBalance).div(1e18).toString()}
                    </span>
                  </p>
                </div>
                <div className="deposit-input-container">
                  <input
                    placeholder="Enter amount to stake"
                    className="deposit-input"
                    value={tokenInput}
                    type="number"
                    onChange={(e) => {
                      setTokenInput(e.target.value);
                    }}
                    // defaultValue={0}
                  />
                  <button
                    className="deposit-max-button"
                    onClick={() => {
                      setTokenInput( new BigNumber(pool.myTokenBalance).div(1e18).toString());
                    }}
                  >
                    Max
                  </button>
                </div>
                <div className="mobile-card-gap" />
                <div className="mobile-pool-line">
                  {estApr ? (
                    <p className="mobile-pool-line deposit-text">
                      Est. APR:&nbsp;
                      <span className="mobile-pool-number">{estApr}%</span>
                    </p>
                  ) : null}
                </div>

                <div className="mobile-pool-line deposit-text">
                  <h3>Your Deposit</h3>
                  <h3 className="mobile-pool-line deposit-text">
                    <h3 className="mobile-pool-number">
                      {getHandsomeNumber(pool.myStakeAmount)} {pool.name}
                    </h3>
                  </h3>
                </div>

                <div className="mobile-pool-line deposit-text">
                  <h3>Pending Reward</h3>
                  <h3 className="mobile-pool-line deposit-text">
                    <h3 className="mobile-pool-number">
                      {getHandsomeNumber(pool.pendingReward)} iBG
                    </h3>
                  </h3>
                </div>
{/* 
                <div className="mobile-pool-line deposit-text">
                  <h3>Your Unlocked Principal</h3>
                  <h3 className="mobile-pool-line deposit-text">
                    <h3 className="mobile-pool-number">
                      {getHandsomeNumber(pool.pendingReward)} iBG
                    </h3>
                  </h3>
                </div> */}

                <br />

                {pool.deposits.length > 0 ? (
                  <table id="customers" className="responsive">
                    <tbody>
                      <tr>
                        <th className="pool-legend">Amount </th>
                        <th className="pool-legend">Start Date</th>
                        <th className="pool-legend">End Date</th>
                        <th className="pool-legend" />
                      </tr>

                      {pool.deposits.map((deposit) => {
                        return (
                          <tr>
                            <td className="pool-tlv">
                              {getHandsomeNumber(deposit.amount)}
                            </td>
                            <td className="pool-apy">
                              {moment(deposit.start).format("DD-MMM-YYYY")}
                              <br />
                              {moment(deposit.start).format("hh:mm:ss A")}
                            </td>
                            <td className="pool-apy">
                              {moment(deposit.end).format("DD-MMM-YYYY")}
                              <br />
                              {moment(deposit.end).format("hh:mm:ss A")}{" "}
                            </td>

                            <td className="pool-buttons-container">
                              <button
                                className={`stake-button ${
                                  Number(deposit.end) > Math.floor(Date.now())
                                    ? "disabled"
                                    : ""
                                }`}
                                disabled={
                                  Number(deposit.end) > Math.floor(Date.now())
                                }
                                onClick={() => {
                                  handleWithdraw(deposit.id);
                                }}
                              >
                                Withdraw
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : null}
                <div className="mobile-card-gap" />
                <span className="staking-disclaimer">
                  Be aware that there are always risks associated with staking
                  contracts.
                  <br />
                  You assume all responsibility.
                </span>
              </>
            ) : null}
            {renderButton()}
            <button
              disabled={isLoading}
              style={{
                marginTop: 10,
                background: "#21204e",
                //  width:"50%"
              }}
              className="stake-button details-page-button"
              onClick={() => {
                // handleApprove();
                window.location.href = window.location.origin;
              }}
            >
              Go Back
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Stake;
