import React, { useEffect, useState } from "react";
import Web3 from "web3";
import abi from "./abi/abis.json";
import { accountUpdate, contractLoaded, ethtodollar } from "../redux/actions";
import { connect, useDispatch, useSelector } from "react-redux";
import { useWallet } from "use-wallet";
import config from "./config";
import { ethers } from "ethers";
import { getPoolContract, getERC20 } from "../utils";
import { loadUserPoolsData } from "../redux/actions";

function BlockchainProvider(props) {
  const { account, ethereum } = useWallet();

  const dispatch = useDispatch();
  const [provider, setProvider] = useState();
  const readWeb3 = new Web3(config.RPC);

  const [web3, setWeb3] = useState(readWeb3);

  const pools = useSelector((state) => {
    return state.pools.data;
  });

  useEffect(() => {
    if (ethereum) {
      loadWeb3(ethereum);
    }
  }, [ethereum]);

  const loadWeb3 = async (provider) => {
    // setProvider(ethereum)
    let _web3 = new Web3(provider);
    const chainId = await _web3.eth.net.getId();
    if (chainId === config.CHAINID) {
      setWeb3(_web3);
    } else {
      //   alert("Invalid Chain");
      setWeb3(readWeb3);
    }
  };

  useEffect(() => {
    if (account) {
      props.dispatch(accountUpdate(account));
    }
  }, [account]);

  useEffect(() => {
    if (account) {
      loadUserPoolsData(pools, account, web3, dispatch);
    }    
  }, [account]);

 
 

  return <div></div>;
}
const mapStateToProps = function (state) {
  return {};
};

export default connect(mapStateToProps)(BlockchainProvider);
