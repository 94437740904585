import React from "react";
import "./App.css";

import theme from "styled-theming";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import styled from "styled-components";
import DarkThemeProvider from "./DarkThemeProvider";
import BlockchainProvider from "./BlockchainProvider";
import Index from "./Page";
import Stake from "./Page/Stake";
import Header from "./components/Header";
// import 'bootstrap/dist/css/bootstrap.min.css';

export const backgroundColor = theme("theme", {
  light: "#a1dcf2",
  dark: "#27343a",
});

const Container = styled.div`
  background: ${backgroundColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: -2;
`;

const App = () => {
  return (
    <DarkThemeProvider>

      <Router>
        <Switch>
          <Route exact path="/" component={Index} />
          <Route path="/stake" component={Stake} />
        </Switch>
      </Router>
      <BlockchainProvider />
    </DarkThemeProvider>
  );
};

export default App;
