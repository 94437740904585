import { createSlice } from "@reduxjs/toolkit";
import pools from "../../BlockchainProvider/config/pools";
export const poolsSlice = createSlice({
  name: "pools",
  initialState: {
    data: [...pools],
  },
  reducers: {
    updatePools: (state, action) => {
      state.data = [...action.payload];
    },

    updatePoolByid: (state, action) => {
      //   state.value += action.payload
    },

    updateUserPool: (state, action) => {
      //   state.value += action.payload
      // const 
    },
  },
});

// Action creators are generated for each case reducer function
export const { updatePools, updatePoolByid, updateUserPool } =
  poolsSlice.actions;

export default poolsSlice.reducer;
