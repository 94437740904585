const getRPC = () => {
  let rps = [
    "https://rpc.ankr.com/eth",
    "https://eth-mainnet.public.blastapi.io",
    "https://eth-mainnet.public.blastapi.io",
    "https://rpc.flashbots.net",
    "https://cloudflare-eth.com",
  ];

  return rps[Math.floor(Math.random() * rps.length)];
};

export default {
  CONTRACT: "0x895bbc29f74df77279bf585116caa24ce33ed0ba",
  RPC: getRPC(),
  CHAINID: 1,
};
