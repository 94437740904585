export default [
  {
    id: 1,
    name: "iBG",
    logos: ["images/tokens/ibg.png"],
    depositTokenAddress: "0xf16cd087e1c2c747b2bdf6f9a5498aa400d99c24",
    earnedTokenAddress: "0xF16CD087e1C2C747b2bDF6f9A5498AA400D99C24",

    stakingContract: "0xF438588291b7fb8A38a0262682dFe4d8A6be79AB",
    isToken: true,
    tvl: 0,
    maxLockDuration:0,
    maxBonus:0,
    totalReward:841108.8,
    buyTokenURL:"https://app.uniswap.org/#/swap?outputCurrency=0xf16cd087e1c2c747b2bdf6f9a5498aa400d99c24&use=V2&chain=mainnet",
    apr: 0,
    tokenValue:0,
    tokenInfo:{
      tokenAddress:"0xF16CD087e1C2C747b2bDF6f9A5498AA400D99C24",
      isLP:false,
    },
    myStakeAmount: 0,
      pendingReward: 0,
      claimedReward: 0,
      myWithdrawalDate: 0,
      myTokenBalance:0,
      isApproved:false,
      deposits:[]
  },
  {
    id: 2,
    name: "iBG-ETH",
    logos: ["images/tokens/ibg.png", "/images/pools/eth.svg"],
    depositTokenAddress: "0xfEcc3B6faB9E07e1f2E6c712D17f86ff2b5eE5aC",
    earnedTokenAddress: "0xF16CD087e1C2C747b2bDF6f9A5498AA400D99C24",
    stakingContract: "0x94254D397A2217f0827ec87Ee2E2426972625e47",
    tokenValue:0,
    buyTokenURL:"https://app.uniswap.org/#/add/v2/0xf16cd087e1c2c747b2bdf6f9a5498aa400d99c24/ETH?chain=mainnet",
    tokenInfo:{
      tokenAddress:"0xfEcc3B6faB9E07e1f2E6c712D17f86ff2b5eE5aC",
      isLP:true,
    },

    totalReward:1261664,
    tvl: 0,
    isToken: false,
    maxLockDuration:0,
    maxBonus:0,
    apr: 0,
    myStakeAmount: 0,
    pendingReward: 0,
    claimedReward: 0,
    myWithdrawalDate: 0,
    myTokenBalance:0,
    isApproved:false,
    deposits:[]
  },

  {
    id: 3,
    name: "iBG-USDT",
    logos: ["images/tokens/ibg.png", "/images/pools/USDT.png"],
    depositTokenAddress: "0x81a10a45143d7a6b7e1a0e63761b4fba311bb661",
    earnedTokenAddress: "0xF16CD087e1C2C747b2bDF6f9A5498AA400D99C24",
    stakingContract: "0x9F67ED7ba01e313D0A450e5837a20ab86501F07b",
    tokenValue:0,
    buyTokenURL:"https://app.uniswap.org/#/add/v2/0xf16cd087e1c2c747b2bdf6f9a5498aa400d99c24/ETH?chain=mainnet",
    tokenInfo:{
      tokenAddress:"0x81a10a45143d7a6b7e1a0e63761b4fba311bb661",
      isLP:true,
    },

    totalReward:1261664,
    tvl: 0,
    isToken: false,
    maxLockDuration:0,
    maxBonus:0,
    apr: 0,
    myStakeAmount: 0,
    pendingReward: 0,
    claimedReward: 0,
    myWithdrawalDate: 0,
    myTokenBalance:0,
    isApproved:false,
    deposits:[]
  },
];
