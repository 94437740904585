import ConnectWallet from "../components/ConnectWallet";
import { useWallet } from "use-wallet";
import { useSelector } from "react-redux";
import { getHandsomeNumber } from "../utils";
import { useEffect, useState } from "react";
import { getStakingTokenPrice } from "../apollo";
import styled from "styled-components";

const Container = styled.div`
  // padding: 0 0 0 10rem;
  @media only screen and (max-width: 1400px) {
    padding-left: 5rem;
  }
`;

const Index = () => {
  const goToRoute = (addr, openInNew) => {
    if(openInNew){
      window.open(addr, '_blank').focus();

    }else{
      window.location = addr;

    }
  };

  const pools = useSelector((state) => state.pools.data);
  const { account } = useWallet();
  const [totalStaked, setTotalStaked] = useState(0);
  const [totalStakedUSD, setTotalStakedUSD] = useState(0);

  const [totalRewardUSD, setTotalRewardUSD] = useState(0);
  const [totalReward, setTotalReward] = useState(0);
  const [ibgTokenPrice, setIbgTokenPrice] = useState("");
  const [globalTVL, setGlobalTVL] = useState("");

  useEffect(() => {
    (async () => {
      const ibgPrice = await getStakingTokenPrice(
        "0xf16cd087e1c2c747b2bdf6f9a5498aa400d99c24",
        false
      );
      setIbgTokenPrice(ibgPrice);
      console.log("sasasa", ibgPrice);
    })();
  }, []);
  useEffect(() => {
    let _totalStaked = 0;
    let _globalTVL = 0;

    let totatUSDStaked = 0;
    let _totalReward = 0;
    for (let pool of pools) {
      _globalTVL = _globalTVL + Number(pool.tvl);
      for (let deposit of pool.deposits) {
        console.log("globssaaa", deposit);
        totatUSDStaked =
          totatUSDStaked + (deposit.amount / 1e18) * pool.tokenPrice;
        _totalStaked = _totalStaked + Number(deposit.amount);
      }
      _totalReward = _totalReward + Number(pool.pendingReward);
    }

    console.log("_totalStaked", _totalStaked / 1e18);
    setTotalStaked(_totalStaked / 1e18);
    setTotalStakedUSD(totatUSDStaked.toFixed(2));

    setGlobalTVL(_globalTVL);

    setTotalReward(_totalReward);
    setTotalRewardUSD(_totalReward);
  }, [pools]);

  return (
    <Container className="staking-container">
      <div style={{ opacity: 1, transform: "none" }}>
        <div className="row">
          <div
            className="column"
            style={{
              paddingBottom: 0,
            }}
          >
            <h2 className="staking-title">iBG Flexible Lock Staking</h2>
          </div>
          <div
            className="column"
            style={{
              paddingBottom: 0,
            }}
          >
            <p
              className="staking-text"
              style={{
                padding: 10,
                marginBottom: 10,
              }}
            >
              iBG Finance elevates locked staking to greater heights. Take your
              yield to higher pinnacles today for $iBG and LP staking pools.
              <br />
              <br />
              Staking Period - 1 to 12 months
              <br />
              <br />
              Staking Rewards are locked for 1 year after claiming.
              <br />
            </p>
          </div>
        </div>
        <div className="row">
          <div
            className="Staking column "
            style={{
              paddingBottom: 0,
            }}
          >
            <div className="card" style={{ opacity: 1, transform: "none" }}>
              <img
                alt=""
                className="icon"
                src="/static/media/stake.675d343f.svg"
              />
              <span className="card-title">Your Total Stake</span>

              {account ? (
                <>
                  {" "}
                  <span className="number">${totalStakedUSD}</span>
                  {/* <span className="small-number">
                    {getHandsomeNumber(totalStaked)}
                    <img
                      alt=""
                      style={{
                        marginLeft: 5,
                      }}
                      src="https://eth.ibg.finance/images/IBG.png"
                    />
                    iBG
                  </span> */}
                  <div className="divider" />
                </>
              ) : null}

              <a href="#pools">
                {account ? (
                  <button
                    className="stake-button width-fix"
                    onClick={() => {
                      goToRoute("#stake/");
                    }}
                  >
                    Stake
                  </button>
                ) : (
                  <ConnectWallet />
                )}
              </a>
            </div>
          </div>
          <div
            className="Rewards column"
            style={{
              marginLeft: 10,
              marginRight: 10,
              paddingBottom: 0,
            }}
          >
            <div className="card" style={{ opacity: 1, transform: "none" }}>
              <img
                alt=""
                className="icon"
                src="/static/media/rewards.6e73b96c.svg"
              />
              <span className="card-title">Unclaimed Rewards</span>
              {account ? (
                <>
                  <span className="number">
                    ${getHandsomeNumber(totalRewardUSD)}
                  </span>
                  <span className="small-number">
                    {getHandsomeNumber(totalReward)}
                    <img
                      alt=""
                      src="https://eth.ibg.finance/images/IBG.png"
                      style={{
                        marginLeft: 5,
                      }}
                    />
                    iBG
                  </span>
                </>
              ) : null}
              <div className="divider" />
              <a href="/rewards"></a>

              {account ? (
                <button className="stake-button width-fix">Claim</button>
              ) : (
                <ConnectWallet />
              )}
            </div>
          </div>

          <div
            className="Rewards column"
            style={{
              marginLeft: 10,
              marginRight: 10,
              paddingBottom: 0,
            }}
          >
            <div className="card" style={{ opacity: 1, transform: "none" }}>
              <img
                alt=""
                className="icon"
                src="/static/media/rewards.6e73b96c.svg"
              />
              <span className="card-title">Total Amount Staked</span>
              <span className="number">$ {globalTVL} </span>
              <div className="divider" />

              <span className="card-title">iBG Token Price</span>
              <span className="number">${ibgTokenPrice}</span>
            </div>
          </div>
        </div>

        <div className="container">
          <div
            id="pools"
            className="Pools PoolSelector"
            style={{ opacity: 1, transform: "none" }}
          >
            <h2
              className="staking-title smaller-title"
              style={{ color: "#1c1745", marginTop: 10, marginBottom: 30 }}
            >
              Pools
            </h2>
            <div className="card">
              <table id="customers">
                <tbody>
                  <tr>
                    <th className="pool-legend">Core Pools</th>
                    <th className="pool-legend">Total Value Locked</th>
                    <th className="pool-legend">APR</th>
                    <th className="pool-legend" />
                  </tr>

                  {pools.map((item) => {
                    return (
                      <tr>
                        <td className="pool-name">
                          <div className="coin-icons undefined">
                            {item.logos.map((src) => {
                              return <img width={24} height={24} src={src} />;
                            })}
                          </div>
                          {item.name}
                        </td>
                        <td className="pool-tlv">${item.tvl}</td>
                        <td className="pool-apy">{item.apr.toFixed(2)}%</td>
                        <td
                          className="pool-buttons-container row"
                          style={{
                            width: "100%",
                          }}
                        >
                          {
                            <button
                            className="stake-button"
                            onClick={() => {
                              goToRoute(
                                item.buyTokenURL,
                                true
                              );
                            }}
                          >
                            Buy {item.isToken?item.name:"LP"}
                          </button>
                          }

                          <button
                            className="stake-button"
                            onClick={() => {
                              goToRoute(`/stake?id=${item.id}`);
                            }}
                          >
                            Stake
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div
            id="pools"
            className="MobilePools MobilePoolSelector"
            style={{ opacity: 1, transform: "none" }}
          >
            <h2 className="staking-title smaller-title">Pools</h2>
            {pools.map((item) => {
              return (
                <div className="card mobile-pool-card">
                  <h3 className="mobile-pool-name">
                    {item.logos.map((src) => {
                      return <img width={24} height={24} src={src} />;
                    })}
                    {item.name}
                  </h3>
                  <div className="mini-divider" />
                  <div className="mobile-card-gap" />
                  <div className="mobile-pool-line">
                    <p>TVL</p>
                    <p className="mobile-pool-number">${item.tvl}</p>
                  </div>

                  <div className="mobile-pool-line">
                    <p className="mobile-pool-title">APR</p>
                    <p className="mobile-pool-number">{item.apr.toFixed(2)}%</p>
                  </div>

                  <div className="mobile-pool-buttons-container">
                    {/* <button
                      className="details-button"
                      style={{
                        background: "#4c5ada",
                        color: "#fff !important",
                        boxShadow: "0px 0px 10px 4px #4c5bd8;",
                      }}
                    >
                      <a
                        href="https://app.uniswap.org/#/add/v2/0xf16cd087e1c2c747b2bdf6f9a5498aa400d99c24/ETH?chain=mainnet"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Buy LP
                      </a>

                      
                    </button> */}

{
                            <button
                            className="stake-button"
                            onClick={() => {
                              goToRoute(
                                item.buyTokenURL,
                                true
                              );
                            }}
                          >
                            Buy {item.isToken?item.name:"LP"}
                          </button>
                          }
                    <button

                      className="stake-button"
                      onClick={() => {
                        goToRoute(`/stake?id=${item.id}`);
                      }}
                    >
                      Stake
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Index;
