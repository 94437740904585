import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";

const DarkThemeProvider = ({ children }) => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);
  const [sidebarWidth,setSideBarWidth] = useState(240);

  const darkThemeEnabled = useSelector((state) => state.darkThemeEnabled);
  return (
    <ThemeProvider theme={{ theme: darkThemeEnabled ? "dark" : "light" }}>
      <Header
        onOpen={() => {
          setOpen(!isOpen);
        }}
      />

      <Sidebar
        ref={ref}
        style={{
          width: "auto",
        }}
        isOpen={isOpen}
        toggleOpen={() => {
          setOpen(!isOpen);
          setSideBarWidth(ref.current.offsetWidth)
        }}
      />
      <div
        style={{
          width: "100%",
          // marginLeft: isOpen ? sidebarWidth-50 :  sidebarWidth-50 ,
        }}
      >
        {children}
      </div>
    </ThemeProvider>
  );
};

export default DarkThemeProvider;
